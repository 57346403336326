/** Custom Date Picker */

'use strict'

$(document).ready(function () {
  // Show the datepicker on input field focused
  $(".custom-datepicker__input").focus(function () {
    $(".custom-datepicker__input").datepicker("show");
  });

  // Trigger focus input field on container clicked
  $('.js-custom-datepicker').on('click', function (e) {
    e.preventDefault();
    e.stopPropagation();
    $(this).find('.custom-datepicker__input').focus();
  });

  // Datepicker initialization
  $('#datepicker-1').datepicker({
    orientation: "bottom auto",
    todayHighlight: true
  });
})